import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Iskill, isMelee, isShooting as isShootingFn } from 'store/characters/skillModel';
import { FormGroup } from 'ui/FormGroup';
import { Checkbox } from 'ui/Checkbox';
import { useMemo } from 'react';
import { Icharacter } from 'store/characters';
import { Table } from 'ui/Table';
import { RateOfFireAndReload } from './RateOfFireAndReload';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 18px;
`;

const WeaponSelect = styled(FormGroup)`
  grid-area: 1 / 1 / 1 / 4;
`;

const WeaponTable = styled(Table)`
  grid-area: 2 / 1 / 2 / 4;
`;

const WeaponModifiers = styled.div`
  grid-area: 4 / 1 / 4 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;

  & > h4 {
    grid-area: 1 / 1 / 1 / 4;
  }
`;

interface WeaponOptionsProps {
  character: Icharacter;
  attackSkill: Iskill;
}

export const WeaponOptions = observer(function WeaponOptionsFn({
  attackSkill,
  character,

  ...otherProps
}: WeaponOptionsProps) {
  const isShooting = useMemo(() => isShootingFn(attackSkill), [attackSkill]);
  const weapons = useMemo(
    () => character.getWeaponsByAttackSkill(attackSkill),
    [character, attackSkill]
  );

  const { currentlyHoldWeapon } = character;

  return (
    <fieldset {...otherProps}>
      <legend>Weapon Options</legend>
      <GridContainer>
        <WeaponSelect
          label="Weapon"
          input={({ id }) => (
            <select
              id={id}
              name="currentWeapon"
              value={currentlyHoldWeapon.id}
              onChange={(event) => character.set('currentlyHoldWeapon', event.target.value as any)}
            >
              {!weapons.includes(currentlyHoldWeapon) && (
                <option key="no_current_weapon">Please select a weapon</option>
              )}
              {weapons.map((weapon) => (
                <option key={weapon.id} value={weapon.id}>
                  {weapon.name}
                </option>
              ))}
            </select>
          )}
        />
        {isShooting && currentlyHoldWeapon.isRangedWeapon && (
          <>
            <WeaponTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Range</th>
                  <th>Damage</th>
                  <th>AP</th>
                  <th>RoF</th>
                  <th>Shots</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currentlyHoldWeapon.name}</td>
                  <td>{`${currentlyHoldWeapon.range[0]} / ${currentlyHoldWeapon.range[1]} / ${currentlyHoldWeapon.range[2]}`}</td>
                  <td>{currentlyHoldWeapon.damage.humanFriendly}</td>
                  <td>{currentlyHoldWeapon.armorPiercing}</td>
                  <td>{currentlyHoldWeapon.rateOfFire}</td>
                  <td>{`${currentlyHoldWeapon.remainingAmmunition} / ${currentlyHoldWeapon.shots}`}</td>
                </tr>
              </tbody>
            </WeaponTable>
          </>
        )}
        {isMelee(attackSkill) && currentlyHoldWeapon.isMeleeWeapon && (
          <>
            <WeaponTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Damage</th>
                  <th>AP</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currentlyHoldWeapon.name}</td>
                  <td>
                    {currentlyHoldWeapon.damage.humanFriendly.replace(
                      'STR',
                      `STR (${character.attributes.strength.value})`
                    )}
                  </td>
                  <td>{currentlyHoldWeapon.armorPiercing}</td>
                  <td>{currentlyHoldWeapon.notes}</td>
                </tr>
              </tbody>
            </WeaponTable>
          </>
        )}
        {currentlyHoldWeapon.isForSkill(attackSkill.name) && (
          <>
            {isShooting && (
              <RateOfFireAndReload attackSkill={attackSkill} weapon={currentlyHoldWeapon} />
            )}
            <WeaponModifiers>
              <h4>Weapon Modifiers</h4>
              {currentlyHoldWeapon.modifiers.length > 0 && (
                <>
                  {currentlyHoldWeapon.modifiers.map((modifier, index) => (
                    <Checkbox
                      key={index}
                      label={modifier.name}
                      checked={character.activeModifiers.includes(modifier)}
                      onChange={() => modifier.set('isActive', !modifier.isActive)}
                    />
                  ))}
                </>
              )}

              {isShooting && attackSkill.skillOptions.rateOfFire !== 1 && (
                <Checkbox
                  label={`Recoil (${attackSkill.getModifiersAccumulator().boni.recoil})`}
                  checked={attackSkill.skillOptions.isRecoil}
                  onChange={() =>
                    attackSkill.skillOptions.set('isRecoil', !attackSkill.skillOptions.isRecoil)
                  }
                />
              )}

              {isShooting && currentlyHoldWeapon.weaponType.includes('shotgun') && (
                <Checkbox
                  label="Use slugs"
                  checked={attackSkill.skillOptions.isShotgunSlugs}
                  onChange={() =>
                    attackSkill.skillOptions.set(
                      'isShotgunSlugs',
                      !attackSkill.skillOptions.isShotgunSlugs
                    )
                  }
                />
              )}
              {isShooting && currentlyHoldWeapon.isThreeRoundBurstSelectable && (
                <Checkbox
                  label="Three Round Burst"
                  checked={attackSkill.skillOptions.isThreeRoundBurst}
                  onChange={() =>
                    attackSkill.skillOptions.set(
                      'isThreeRoundBurst',
                      !attackSkill.skillOptions.isThreeRoundBurst
                    )
                  }
                />
              )}
              {isMelee(attackSkill) && (
                <Checkbox
                  label="Non lethal damage with edged weapon"
                  checked={attackSkill.skillOptions.isNonLethal}
                  onChange={() =>
                    attackSkill.skillOptions.set(
                      'isNonLethal',
                      !attackSkill.skillOptions.isNonLethal
                    )
                  }
                />
              )}
              {currentlyHoldWeapon.isTwoHanded && (
                <Checkbox
                  label="One handed use of two hand weapon"
                  checked={attackSkill.skillOptions.isOneHandedAttack}
                  onChange={() =>
                    attackSkill.skillOptions.set(
                      'isOneHandedAttack',
                      !attackSkill.skillOptions.isOneHandedAttack
                    )
                  }
                />
              )}
            </WeaponModifiers>
          </>
        )}
      </GridContainer>
    </fieldset>
  );
});
